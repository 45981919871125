<template>
	<div class="accountmanage">
		<h1 class="page_title">{{ $route.meta.title }}</h1>
		<a-form
			class="ant-advanced-search-form"
			:form="form"
			@submit="handleSearch"
		>
			<a-row :gutter="24">
				<a-col :span="6">
					<a-form-item label="角色">
						<a-select
							placeholder="请选择角色"
							v-decorator="['roleId', { initialValue: 0 }]"
						>
							<a-select-option key="role-0" :value="0">
								全部
							</a-select-option>
							<a-select-option
								v-for="(item, index) in roleList.filter(
									item => item.modified
								)"
								:key="'role' + index"
								:value="item.id"
							>
								{{ item.name }}
							</a-select-option>
						</a-select>
					</a-form-item>
				</a-col>
				<a-col :span="6">
					<a-form-item label="姓名">
						<a-input
							v-decorator="['name']"
							placeholder="请输入姓名"
							autocomplete="off"
						/>
					</a-form-item>
				</a-col>
				<a-col :span="6">
					<a-form-item label="手机号">
						<a-input
							v-decorator="['phone']"
							placeholder="请输入手机号"
							autocomplete="off"
						/>
					</a-form-item>
				</a-col>
				<a-col
					:span="6"
					:style="{
						textAlign: 'left',
						height: '40px',
						lineHeight: '40px'
					}"
				>
					<a-button type="primary" html-type="submit">
						查询
					</a-button>
					<a-button
						:style="{ marginLeft: '8px' }"
						@click="handleReset"
					>
						重置
					</a-button>
				</a-col>
			</a-row>
		</a-form>

		<a-button
			type="primary"
			@click="addAccount"
			style="margin-bottom: 16px"
		>
			+ 新增
		</a-button>
		<a-table
			rowKey="id"
			:columns="table_col"
			:data-source="table_data"
			:pagination="table_pagination"
			:loading="table_loading"
			@change="tableChange"
		>
			<template slot="status" slot-scope="text">
				<a-badge :status="text == 0 ? 'error' : 'success'" 
						:text="text == 0 ? '冻结' : '正常'"/>
			</template>
			<template
				slot="active"
				slot-scope="text, record"
				v-if="record.modified"
			>
				<a href="javascript:;" @click="editAccount(record)">编辑</a>
				<a-divider type="vertical"></a-divider>
				<a href="javascript:;" @click="enableAccount(record)">
					{{ record.status == 0 ? "激活" : "冻结" }}
				</a>
			</template>
		</a-table>

		<a-modal
			:title="modal.visible === 'add' ? '新增账号' : '编辑账号'"
			:visible="modal.visible && true"
			:confirmLoading="modal.loading"
			@cancel="cancelModal"
			@ok="okModal"
		>
			<a-form
				:form="modal.form"
				:label-col="{ span: 6 }"
				:wrapper-col="{ span: 16 }"
			>
				<a-form-item label="账号名称">
					<a-input
						:disabled="modal.visible === 'edit'"
						autocomplete="off"
						placeholder="4-10位支持中文英文数字"
						:readonly="readonly"
						@focus="readonly = false"
						v-decorator="[
							'userName',
							{
								rules: [
									{
										required: true,
										message: '4-10位支持中文英文数字',
										pattern: /^[\u4e00-\u9fa50-9a-zA-Z]{4,10}$/
									}
								],
								initialValue: modal.info.userName
							}
						]"
					/>
				</a-form-item>

				<a-form-item label="密码">
					<a-input
						:readonly="readonly"
						@focus="readonly = false"
						:disabled="modal.visible === 'edit'"
						:style="{
							width: `${
								modal.visible === 'add' ? '100%' : '200px'
							}`,
							marginRight: '16px'
						}"
						type="password"
						autocomplete="off"
						placeholder="8-20位大小写字母和数字组合"
						v-decorator="[
							'password',
							{
								rules: [
									{
										required: true,
										message: '8-20位大小写字母和数字组合',
										pattern: /(?=\S*\d)(?=\S*[a-z])(?=\S*[A-Z])^[\S]{8,20}$/
									}
								],
								initialValue:
									modal.visible === 'edit' ? 'Aa123456789' : ''
							}
						]"
					/>
					<a-popconfirm
						v-if="modal.visible === 'edit'"
						title="确认重置密码为：Aa123456789"
						ok-text="确定"
						cancel-text="取消"
						@confirm="resetPwd"
					>
						<a href="#">重置密码</a>
					</a-popconfirm>
				</a-form-item>
				<a-form-item label="姓名">
					<a-input
						autocomplete="off"
						placeholder="请输入姓名"
						v-decorator="[
							'name',
							{
								rules: [
									{
										required: true,
										message: '请输入姓名'
									}
								],
								initialValue: modal.info.name
							}
						]"
					/>
				</a-form-item>
				<a-form-item label="手机号">
					<a-input
						autocomplete="off"
						placeholder="请输入手机号"
						v-decorator="[
							'phone',
							{
								rules: [
									{
										required: false,
										message: '请输入正确手机号',
										pattern: /^1[3|4|5|6|7|8|9][0-9]{9}$/
									}
								],
								initialValue: modal.info.phone
							}
						]"
					/>
				</a-form-item>
				<a-form-item label="邮箱">
					<a-input
						autocomplete="off"
						placeholder="请输入邮箱"
						v-decorator="[
							'email',
							{
								rules: [
									{
										required: false,
										message: '请输入正确的邮箱',
										pattern: /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/
									}
								],
								initialValue: modal.info.email
							}
						]"
					/>
				</a-form-item>
				<a-form-item label="角色">
					<a-select
						placeholder="请选择角色"
						v-decorator="[
							'roleIdList',
							{
								rules: [
									{
										required: true,
										message: '请选择角色'
									}
								],
								initialValue: modal.info.roleIdList
							}
						]"
					>
						<a-select-option
							v-for="(item, index) in roleList.filter(
								item => item.modified
							)"
							:key="'rolemodal' + index"
							:value="item.id"
						>
							{{ item.name }}
						</a-select-option>
					</a-select>
				</a-form-item>
				<!-- <a-form-item label="公司">
					<a-select
						mode="multiple"
						placeholder="请选择公司"
						v-decorator="[
							'companyIdList',
							{
								rules: [
									{
										required: true,
										message: '请选择公司'
									}
								],
								initialValue: modal.info.companyIdList
							}
						]"
					>
						<a-select-option
							v-for="(item, index) in companyList"
							:key="'site' + index"
							:value="item.id"
						>
							{{ item.name }}
						</a-select-option>
					</a-select>
				</a-form-item> -->
			</a-form>
		</a-modal>
	</div>
</template>
<script>
	import { 
		userSearchList, 
		roleList, 
		//companyList, 
		userRegister, 
		userUpdate, 
		userInfo, 
		userEnable,
		userDisable,
		resetPassword} from "@/api/index";
	export default {
		name: "AccountManage",
		data() {
			return {
				form: this.$form.createForm(this),
				// 所有角色列表
				roleList: [],
				// 所有公司列表
				//companyList: [],
				// 列
				table_col: [
					{
						title: "序号",
						dataIndex: "key",
						customRender: (text, record, index) => {
							return (
								(this.table_pagination.current - 1) *
									this.table_pagination.pageSize +
								(index + 1)
							);
						}
					},
					{
						title: "角色",
						dataIndex: "roleName"
					},
					{
						title: "账号名",
						dataIndex: "userName"
					},
					{
						title: "姓名",
						dataIndex: "name",
						customRender: ( record) => {
							return record ? record :"---";
						}
					},
					{
						title: "手机号",
						dataIndex: "phone",
						customRender: ( record) => {
							return record ? record :"---";
						}
					},
					{
						title: "最后登录时间",
						dataIndex: "recentVisitTime",
						customRender: ( record) => {
							return record ? record :"---";
						}
					},
					{
						title: "冻结状态",
						dataIndex: "status",
						scopedSlots: {
							customRender: "status"
						}
					},
					{
						title: "操作",
						dataIndex: "active",
						scopedSlots: {
							customRender: "active"
						}
					}
				],
				modal: {
					form: this.$form.createForm(this),
					visible: false,
					info: {},
					loading: false
				},
				readonly: true
			};
		},
		created() {

		},
		mounted() {
			this.getRoleList();
			this.getCompanyList();
			this.getTableList();
		},
		methods: {
			// 账号列表
			getTableList(values) {
				this.table_loading = true;

				let params = values || this.form.getFieldsValue();
				userSearchList({
							current: this.table_pagination.current,
							pageSize: this.table_pagination.pageSize,
							...params,
							roleId: params.roleId == 0 ? null : params.roleId
						})
						.then(res => {
							this.table_data = res.data.list;
							this.table_pagination.total = res.data.total;
						})
						.catch(() => (this.table_data = []))
						.finally(() => (this.table_loading = false));
			},
			// 角色列表
			getRoleList() {
				roleList({}).then(res => {
					this.roleList = res.data;
				});
			},
			// 公司列表
			getCompanyList() {
				// companyList({}).then(res => {
				// 	this.companyList = res.data;
				// });
			},
			// 搜索
			handleSearch(e) {
				e.preventDefault();
				this.form.validateFields((error, values) => {
					console.log("error", error);
					console.log("Received values of form: ", values);
					if (!error) {
						this.getTableList(values);
					}
				});
			},
			// 重置
			handleReset() {
				this.form.resetFields();
				this.getTableList();
			},
			// 编辑
			editAccount(record) {
				console.log(record);
				userInfo( { userId: record.id })
					.then(res => {
						let roleIdList = [];
						Object.values(res.data.roleMap).map(item => {
							if (
								this.roleList.some(
									role => role.id == item.id && role.modified
								)
							) {
								roleIdList.push(item.id);
							}
						});
						//companyInfoMap 的值类似这样 {1: null, 3: null}
						// let companyIdList = [];
						// Object.keys(res.data.companyInfoMap).map(it =>
						// 	companyIdList.push(parseInt(it))
						// );
						this.modal.info = {
							...res.data,
							//companyIdList,
							roleIdList
						};
						this.modal.visible = "edit";
					});
			},
			// 冻结、激活
			enableAccount(record) {
				if (record.status == 0) {
					userEnable({ id: record.id })
						.then(() => {
							this.$message.success("激活成功");
							this.getTableList();
						});
				} else {
					userDisable({ id: record.id })
						.then(() => {
							this.$message.success("冻结成功");
							this.getTableList();
						});
				}
			},
			// 新增
			addAccount() {
				this.modal.info = {
					companyName:
						this.$store.state["_userInfo"].companyName || "公司简称"
				};
				this.modal.visible = "add";
				this.$nextTick(() => this.modal.form.resetFields());
			},
			// 取消
			cancelModal() {
				this.modal.loading = false;
				this.modal.form.resetFields();
				this.modal.visible = false;
			},
			// 确定
			okModal() {
				let fieldNames =
					this.modal.visible == "edit"
						? [
								"name",
								"phone",
								"email",
								//"companyIdList",
								"roleIdList"
						]
						: null;
						
				this.modal.form.validateFields(fieldNames, (err, values) => {
					if (!err) {
						this.modal.loading = true;
						let data = {};
						console.log(values);
						
						if (this.modal.visible == "add") {
							data = {
								...values,
								roleIdList: [values.roleIdList]
							};
							userRegister(data).then(() => {
								this.$message.success("新增成功");
								this.getTableList();
								this.cancelModal();
							});
						} else {
							//需要注意角色的值为数组和非数字的情况
							console.log(values.roleIdList)
							let roleIdList = (values.roleIdList instanceof Object) ? values.roleIdList : [values.roleIdList];
							data = {
								name: values.name,
								email: values.email,
								id: this.modal.info.id,
								phone: values.phone,
								//companyIdList: values.companyIdList,
								roleIdList: roleIdList
							};
							userUpdate(data).then(() => {
								this.$message.success("修改成功");
								this.getTableList();
								this.cancelModal();
							});
						}
						
					}
				});
			},
			// 重置密码
			resetPwd() {
				resetPassword({
						id: this.modal.info.id,
						password: "Aa123456789"
					})
					.then(() => {
						this.password = "Aa123456789";
						if (this.modal.info.id === this.$store.state["_userInfo"].id) {
							this.$message.success("密码重置成功, 请重新登录");
							this.$router.push({ name: "login" });
						} else {
							this.$message.success("密码重置成功");
						}
					});
			}
		}
	};
</script>
<style lang="scss">
	.accountmanage {
		.ant-advanced-search-form .ant-form-item {
			display: flex;
		}

		.ant-advanced-search-form .ant-form-item-control-wrapper {
			flex: 1;
			overflow: hidden;
		}
	}
</style>
